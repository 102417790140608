<template>
  <!-- Version: 1.7 -->
  <h2>1. Описание игры</h2>
  <p>
    Игра Soccerbet от компании Highlight Games является уникальной программной имитацией произвольно выбранных лучших
    моментов футбольных матчей с целью создания игровых возможностей.
  </p>
  <h2>2. Продолжительность и содержание игры</h2>
  <p>
    «Один раунд электронной игры, в которой делаются ставки, длится от 3 до 5 минут. Это время включает анонс матча,
    вступительную часть, внутриигровой показ и результат. Внутриигровой показ длится 1 минуту».
  </p>
  <p>
    Подборка лучших футбольных событий представляет собой независимую, произвольную и непредсказуемую статистическую
    выборку, создаваемую генератором случайных чисел.
  </p>
  <h2>3. Рынки ставок</h2>
  <h3>3.1 Ставка на точное число голов</h3>
  <p>
    Смысл ставки на точное число голов заключается в прогнозе точного количества забитых мячей в одном матче. Таким
    образом, этот вариант ставки означает, что вам необходимо предсказать точное число голов, невзирая на окончательный
    исход всего матча.
  </p>
  <h3>3.2 Ставка на точное число голов команды хозяев</h3>
  <p>Ставка на точное число голов, которые забьет в игре команда хозяев.</p>
  <h3>3.3 Ставка на точное число голов выездной команды</h3>
  <p>Ставка на точное число голов, которые забьет в игре выездная команда.</p>
  <h3>3.4 Ставка «Обе забьют»</h3>
  <p>
    Чтобы выиграть ставку «Обе забьют», обеим командам необходимо забить хотя бы по одному голу, то есть любой результат
    начиная со счета 1-1 и выше является выигрышной ставкой. Ставка «Обе забьют» дает бетторам шанс извлечь преимущество
    из таких событий с целью получения выгодного коэффициента, когда только одна из двух команд смогла забить гол.
  </p>
  <h3>3.5 Ставка на правильный счет</h3>
  <p>
    Ставка на правильный счет – это заключение пари на окончательный исход футбольного матча, при этом есть 28 возможных
    исходов с максимальным количеством 6 голов в одной игре.
  </p>
  <h3>3.6 Ставка на двойной шанс</h3>
  <p>
    Ставка на двойной шанс позволяет вам перекрыть два из трех возможных исходов футбольного матча с помощью одной
    ставки. Ставка «Победа команды хозяев и ничья» будет выигрышной, если хозяева поля одержат победу или сыграют
    вничью. Ставка «Победа выездной команды и ничья» будет выигрышной, если выездная команда одержит победу или сыграет
    вничью.
  </p>
  <h3>3.7 Ставка «Исход матча» (1X2)</h3>
  <p>Ставка на победу хозяев поля (1), победу выездной команды (2) или завершение матча вничью (X).</p>
  <h3>3.8 Ставка «Исход матча и обе забьют» (1X2 + BTTS)</h3>
  <p>
    Ставка на исход матча (1 означает победу хозяев поля, X означает ничью, 2 означает выигрыш выездной команды), а
    также ставка на то, смогут ли обе команды забить хотя бы по одному голу.
  </p>
  <ul>
    <li>
      1 + «Да» – победа команды хозяев плюс ставка на то, что обе команды забьют как минимум по одному мячу [например
      3-1].
    </li>
    <li>
      1 + «Нет» – победа команды хозяев плюс ставка на то, что хозяева поля будут единственной забившей гол командой
      [например 1-0].
    </li>
    <li>X + «Да» – ставка на ничью в игре и на то, что обе команды забьют [например 1-1, 2-2].</li>
    <li>X + «Нет» – ставка на ничью в игре и на то, что ни одна команда не забьет ни одного гола. [Только 0-0].</li>
    <li>
      2 + «Да» – победа выездной команды плюс ставка на то, что обе команды забьют хотя бы по одному голу [например 1-2,
      1-3].
    </li>
    <li>
      2 + «Нет» – победа выездной команды плюс ставка на то, что она будет единственной забившей голы командой [например
      0-1, 0-2].
    </li>
  </ul>
  <h3>3.9 Ставка «Исход матча и больше/ меньше 1.5 голов»</h3>
  <p>Ставка на исход матча 1X2 и на то, что общее число голов будет меньше или больше 1.5 гола.</p>
  <h3>3.10 Ставка «Исход матча и больше/ меньше 2.5 голов»</h3>
  <p>Ставка на исход матча 1X2 и на то, что общее число голов будет меньше или больше 2.5 гола.</p>
  <h3>3.11 Ставка на общее число голов (от 1 до 2, от 1 до 3, от 1 до 4, от 2 до 3, от 2 до 4)</h3>
  <p>
    Ставка на то, что окончательным результатом игры будет следующее общее число голов: либо от 1 до 2 голов, либо от 1
    до 3 голов, либо от 1 до 4 голов, либо от 2 до 3 голов, либо от 2 до 4 голов. Это ставка с вариантами «ДА» или
    «НЕТ».
  </p>
  <h3>3.12 Ставка «Число голов команды хозяев больше/меньше 1.5»</h3>
  <p>Ставка на то, что хозяева поля забьют в матче либо больше, либо меньше, чем 1.5 гола.</p>
  <h3>3.13 Ставка «Число голов выездной команды больше/ меньше 1.5»</h3>
  <p>Ставка на то, что выездная команда забьет в матче либо больше, либо меньше, чем 1.5 гола.</p>
  <h3>3.14 Ставка «Общее число голов больше/меньше 1.5, 2.5, 3.5, 4.5»</h3>
  <p>
    Ставка на то, что общее число голов, забитое обеими командами в матче, будет либо меньше, либо больше, чем 1.5, 2.5,
    3.5 или 4.5 гола.
  </p>
</template>
